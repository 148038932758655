
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  justify-content: center;
}

#index {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #121212;
}

@media (max-width: 780px) {
  #main {
    display: flex;
    flex-direction: column;
  }

  .checkoutPage {
    display: flex;
    flex-direction: column;
  }
  .minCol {
    min-width: 96%;
    max-width: 96%;
  }
  .card-group {
    margin-bottom: 20px;
  }
  .mobile-space-top {
    margin-top: 20px;
  }
  .representant-alert{
    max-width: 500px;
    top: 500px;
    left: 0;
  }
}
@media (min-width: 781px) {
  #main {
    display: flex;
    flex-direction: row;
  }

  .checkoutPage {
    display: flex;
    flex-direction: row;
  }
  .minCol {
    min-width: 35%;
    max-width: 35%;
  }
  .representant-alert {
    max-width: 700px;
    top: 24%;
    left: 1%;
  }
}

h1 {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #FFFFFF;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-start;
}

h2 {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #FFFFFF;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-start;
}

h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  color: #FFFFFF;
  padding-bottom: 20px;
  display: flex;
  justify-content: flex-start;
}

h4 {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  color: #FFFFFF;
  padding-bottom: 50px;
  display: flex;
  justify-content: flex-start;
}

h5 {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  color: #FFFFFF;
  padding-bottom: 8px;
  display: flex;
  justify-content: flex-start;
}

h6 {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  color: #FFFFFF;
  display: flex;
  justify-content: flex-start;
}


.h7 {
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  padding-bottom: 30px;
  display: flex;
  justify-content: flex-start;
}

body {
  background-color: #121212;
}

.no-scrolling {
  overflow: hidden;
}

input[type="checkbox"]:checked {
  background-color: var(--brand-color);
}

input[type="radio"] {
  background-color: #D9D9D9;
  color: #D9D9D9;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  appearance: none;
}

input[type="radio"]::before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #D9D9D9;
  transition: background-color 0.3s ease-in-out;
}

input[type="radio"]:checked::before {
  background-color: #D9D9D9;
  border: 4px solid var(--brand-color);
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.show {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.hide {
  opacity: 0;
}

.hide-div {
  display: none !important;
}

.icon{
  cursor: pointer;
  color: var(--brand-color);
  font-size: 22px;
  border: none;
  background: none;
  padding-right: 10px;
}

.icon-big{
  font-size: 42px;
  margin-right: 20px;
}

.img-logo {
  width: 152px;
  margin: 25px;
  display: flex;
}

.pix-column {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
}

.pix-instructions {
  margin-right: 10px;
  width: 45px;
}

.img-instructions {
  width: 45px;
  height: 45px;
}

.txt-instructions {
  text-align: left;
}

.pix-border {
  border: 1px solid #32BCA9
}

.App {
  text-align: center;
}

.userDataCol {
  border-radius: 10px;
  background-color: #242424;
  margin-bottom: 20px;
  padding: 20px;
}

.userDataColDisabled {
  background-color: #1c1c1c;
}

.userDataContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.mainCol {
  margin: 0px 20px 0px 2px;
}

.representant-alert{
  position: absolute;
  z-index: 10;
}
.alert-danger {
  background-color: #D33C3C;
  border: none;
  color: #FFFFFF;
}

.responseColContainer {
  border-radius: 10px;
  background-color: #212121;
  margin-bottom: 20px;
  padding: 50px;
}
.responseFrameTitle {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding: 0px 10%;
}
.responseFramePixTitle {
  display: flex !important;
  flex-direction: column;
  padding: 30px 10%;
}
.responseImage {
  width: 132px;
  height: 132px;
  margin-bottom: 30px;
  margin-top: 10px;
}
.pixImage {
  width: 276px;
  height: 276px;
  margin-bottom: 30px;
  margin-top: 10px;
}
.pixSuccessImage {
  width: 28px;
  height: 28px;
  margin-right: 15px;
}
.responseBorder {
  border: 0.5px dashed #444444;
  width: 80%;
  margin-bottom: 30px;
}
.responseInfoTitle {
  color: #646464;
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}
.responseInfo {
  color: #949494;
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}
.responseFrame {
  display: flex !important;
  padding-top: 10px;
  flex-direction: row;
}
.response-btn {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.minCol {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.normal-text {
  color: #D7D7D7;
}

.header-title {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
}

.login_ddi {
  min-width: 28%;
  max-width: 28%;
}

.payment-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  padding: 10px;
  color: #FFFFFF
}

.payment-name {
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #FFFFFF;
}

.form-group-payment {
  padding-bottom: 30px;
  height: 92px;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 6px;
}

.info-btn {
  cursor: pointer;
  color: #FFFFFF;
  font-size: 20px;
  margin-left: 30px;
  border: none;
  background: none;
}

#login .form-check-input[type=checkbox] {
  appearance: none; 
  background-color: #323232;
  border: none;
  border-radius: 8px;
  width: 42px;
  height: 42px;
  cursor: pointer;
  position: relative;
  margin-right: 10px;

  &::after {
    content: '';
    position: absolute;
    width: 42px;
    height: 42px;
    border-radius: 5px;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
  }

  &:checked::after {
    background-color: var(--brand-color);
    background-image: url('../assets/checked.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

.login-checkbox-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  color: #FFFFFF;
}

.check-group { 
  height: 58px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: #A7A7A7;
}

.terms-advisor {
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF !important;
}

.product-info {
  color: #989898;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.link {
  border: none;
  background: none;
  color: var(--brand-color);
  text-decoration: underline;
  cursor: pointer;
  display: flex;
}

.toast-alert {
  position: absolute;
  top: 73%;
  left: 0;
  display: flex;
  justify-content: center;
  height: 72px;
}

.terms-screen {
  box-sizing: border-box;
  width: 94vw;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.terms-title {
  background-color: var(--brand-color);
}

.terms-title-border {
  border-radius: 20px;
  padding: 30px 20px 0px 20px ;
}

.terms-title-border h1 {
  font-size: 28px;
  font-weight: 600;
  color: #FFFFFF !important;
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
}
.terms-title-border h6 {
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 20px;
  padding-left: 10px;
}

.terms-col {
  background-color: #242424;
  height: 70vh;
  margin: 0px 0px 0px 0px;
  border-radius: 0px 0px 20px 20px;
}

.terms-title-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 20px;
}

.terms-close-btn {
  cursor: pointer;
  color: #FFFFFF;
  font-size: 28px;
  border: none;
  background: none;
}

.terms-btn {
  width: 180px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.terms-textarea {
  width: 100%;
  height: 100%;
  resize: none;
  border: none; 
  outline: none; 
  overflow-y: auto; 
  padding: 30px 50px;
  box-sizing: border-box; 
  font-size: 18px;
}

.textarea-simulated {
  padding: 20px;
  min-height: 100px;
  overflow-y: auto;
  resize: both; 
}

.terms-layer {
  position: relative;
  bottom: 90px;
  height: 180px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%; 
  border-radius: 0px 0px 20px 20px;
}
.terms-layer-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #212121;
  border-radius: 8px;
}
#terms {
  & ::-webkit-scrollbar-thumb {
    background-color: var(--brand-color);
    border-radius: 8px;
  }
}
::-webkit-scrollbar {
  width: 10px; 
}
::-webkit-scrollbar-track {
  background: #3E3D3D;
  border-radius: 8px;
}

#cart {
  & ul {
    list-style-type: none;
    padding: 0;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  & li {
    display: flex;
    flex-direction: row;
    padding: 5px 0px;
  }
  & li::before {
    content: "\e5ca"; 
    font-family: "Material Symbols Outlined";
    font-size: 22px;
    color: var(--brand-color);
    margin-right: 5px; 
  }
}

.cart-membership-title {
  border-radius: 10px;
  background-color: #292929;
  color: #C8C8C8;
  margin-bottom: 20px;
  padding: 20px;
}

.cart-disclaimer {
  font-weight: 600;
  font-size: 12px;
  color: #4C4C4C;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items:center
}

.cupom-container {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
}

.cupom-info {
  border: 1px solid #585858;
  border-radius: 8px;
  color: #D7D7D7;
  font-size: 16px;
  margin: 20px 20px 60px 20px;
  width: 90%;
}

/*#### Boostrap customizations ####*/
.btn {
  border: none;
  border-radius: 8px;
  padding: 18px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  gap: 10px;
  margin-bottom: 15px;
  height: 76px;
}
.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: var(--brand-color);
}

.btn-primary:disabled {
  background-color: var(--brand-color);
  color: #FFFFFF;
}

.btn-cupom:disabled  {
  background-color: transparent;
  color: #7C7C7C;
  border: 1px solid #7C7C7C;
  font-size: 16px;
  font-weight: 400;
  height: 60px;
  margin: 0px 10px;
}

.input-cupom:disabled {
  background-color: #292929 !important;
}

.form-control {
  border-radius: 8px;
  border: 2px solid #242424;
  background-color: #292929;
  height: 58px;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.form-control:disabled {
  background-color: #232222;
  color:#747474
}

.form-control-disabled {
  background-color: #232222;
  color:#747474;
  border-radius: 8px;
  border: 2px solid #242424;
  height: 58px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  padding: .375rem .75rem;
  width: 100%;
}

.form-control-disabled:focus,
.form-control-disabled:focus-visible {
  border: 2px solid #232222;
  cursor: not-allowed;
}

.form-control:focus {
  border: 2px solid var(--brand-color);
  background-color: #292929;
  color: #FFFFFF;
}

.form-control-payment {
  height: 42px;
  font-size: 16px;
  border-color: #343434;
  background-color: #343434;
  color: #FFFFFF;
}

.form-select {
  --bs-form-select-bg-img: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27white%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e);
  display: block;
}

.label-check {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
}
.form-label {
  font-size: 16px;
  font-weight: 400;
  color: #A7A7A7;
  display: flex;
  justify-content: flex-start;
  padding-left: 5px;
  margin-bottom: 3px;
}

.invalid-feedback {
  text-align: left;
 }

 .form-group {
  padding-bottom: 30px;
  height: 108px;
}

.form-control.is-invalid, .was-validated .form-control:invalid, 
.form-control.is-valid, .was-validated .form-control:valid ,
.form-control.is-valid:focus, .was-validated .form-control:valid:focus,
.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #343434;
}

/*****************************************************************************************************/

.payment-cardboard, .payment-cardboard:hover, .payment-cardboard:focus {
  background-color: #292929;
  color: #FFFFFF;
  border-radius: 8px;
  width: 100%;
}
.payment-cardboard-selected, .payment-cardboard-selected:hover, .payment-cardboard-selected:focus {
  background-color: #343434;
  color: #FFFFFF;
  border-radius: 8px;
}